import { Component, OnInit, ViewChild, ViewEncapsulation  } from '@angular/core';
@Component({
  selector: 'app-services-info',
  templateUrl: './services-info.component.html',
  styleUrls: ['./services-info.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ServicesInfoComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }


}
