import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pv-layout',
  templateUrl: './pv-layout.component.html',
  styleUrls: ['./pv-layout.component.css']
})
export class PvLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



}
