import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-racking-structure-design',
  templateUrl: './racking-structure-design.component.html',
  styleUrls: ['./racking-structure-design.component.css']
})
export class RackingStructureDesignComponent implements OnInit {

  constructor() { }




  ngOnInit() {
  }

}
