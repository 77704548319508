import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastructure-development',
  templateUrl: './infrastructure-development.component.html',
  styleUrls: ['./infrastructure-development.component.css']
})
export class InfrastructureDevelopmentComponent implements OnInit {

  constructor() { }


  ngOnInit() {
  }

}
