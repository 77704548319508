import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-foundational-design',
  templateUrl: './foundational-design.component.html',
  styleUrls: ['./foundational-design.component.css']
})
export class FoundationalDesignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
